import React from "react"
import styled from "styled-components"

import Layout from "../components/layouts/Layout"
import { InteriorBanner } from "../components/elements"
import { SEO, Section, Grid, Col, Color } from "../components/utilities"

import banner from "../images/pool2.jpg"
import { StaticQuery, graphql } from "gatsby"

const DynamicIframe = styled.div`
  iframe {
    width: 100%;
  }
`

const ContactUsPage = () => (
  <Layout>
    <SEO
      title="Contact Us For Pool Build Sylvania Ohio"
      description="Get a quote on our high-quality, luxury swimming pool designs, and build the in ground swimming pool of your dreams in Sylvania Ohio"
    />
    <StaticQuery
      query={graphql`
        query ContactInfo {
          site {
            siteMetadata {
              phone
              email
              address_one
              address_two
            }
          }
        }
      `}
      render={({ site }) => {
        const { phone, email, address_one, address_two } = site.siteMetadata
        return (
          <>
            <InteriorBanner image={banner}>
              <h1>Contact Us</h1>
              <p>Start Building Your Dream Pool Today</p>
            </InteriorBanner>
            <Section>
              <h2>Contact Hunt Pools &amp; Service Today!</h2>

              <Grid>
                <Col>
                  <p>
                    <strong>Email:</strong>{" "}
                    <a
                      href={`mailto:${email}`}
                      style={{ color: Color.primary }}
                    >
                      {email}
                    </a>
                    <br />
                    <br />
                    <strong>Phone:</strong>{" "}
                    <a href={`tel:${phone}`} style={{ color: Color.primary }}>
                      {phone}
                    </a>
                    <br />
                    <br />
                    <strong>Address:</strong>
                    <br />
                    {address_one}
                    <br />
                    {address_two}
                  </p>
                </Col>
                <Col>
                  <DynamicIframe>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2986.427374626254!2d-83.64140134829435!3d41.538342894483236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883c76f96faa717d%3A0xd872690513326301!2s26610%20Eckel%20Rd%20%233B%2C%20Perrysburg%2C%20OH%2043551!5e0!3m2!1sen!2sus!4v1577720945516!5m2!1sen!2sus"
                      height="400"
                      title="Google Maps"
                      frameBorder="0"
                    ></iframe>
                  </DynamicIframe>
                </Col>
              </Grid>
            </Section>
          </>
        )
      }}
    />
  </Layout>
)

export default ContactUsPage
