import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import styled from "styled-components"

import Navbar from "../Navbar"
import { Color, Wrapper, Media, Grid, Type, SROnly } from "../../utilities"

import logo from "../../../images/logo.png"
import { darken } from "polished"

const StyledHeader = styled.header`
  padding: 0;
  color: ${Color.white};
  background-size: cover;
  background-color: ${Color.primary};
  height: 150px;
  overflow-x: hidden;
`

const logoWidth = "250px"

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  .logo {
    max-width: ${logoWidth};
  }

  ${Media.below.mobile`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1em;
    text-align: center;
  `}
`

const HeaderGrid = styled(Grid)`
  grid-template-columns: 3fr 2fr 1fr;
  width: 100%;
  height: 100%;

  ${Media.below.desktop`
    grid-template-columns: 1fr 1fr;
  `}

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const SocialContainer = styled.div`
  padding-top: 1rem;
  text-align: center;

  a {
    color: ${Color.white};
    font-size: 1.5rem;
    margin-left: 1rem;

    &:hover {
      color: ${darken(0.3, Color.primary)};
    }
  }

  ${Media.below.desktop`
    display: none;
  `}
`

const ContactContainer = styled.div`
  padding-top: 1rem;
  font-family: ${Type.header};
  font-size: 1.2rem;
  display: flex;
  justify-content: space-around;

  a {
    text-decoration: none;
    color: ${Color.white};
  }

  ${Media.below.tablet`
    display: none;
  `}
`

const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 1000;

  ${Media.below.tablet`
    justify-content: center;
  `}
`

const HeaderContainer = styled.div`
  height: 205px;
  overflow: hidden;

  ${Media.below.tablet`
    height: auto;
    overflow: auto;
  `}
`

const Header = ({ siteMetadata = {} }) => {
  const { title, social, email, phone } = siteMetadata
  return (
    <HeaderContainer>
      <StyledHeader>
        <StyledWrapper>
          <HeaderGrid>
            <LogoContainer>
              <Link to="/">
                <img src={logo} alt={title} title="View Home Page"></img>
              </Link>
            </LogoContainer>
            <ContactContainer>
              <div>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
              <div>
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
            </ContactContainer>
            <SocialContainer>
              {social.facebook && (
                <a
                  href={social.facebook}
                  target="_blank"
                  title="Visit Hunt Pools & Services Facebook Page"
                  aria-label="Visit Hunt Pools & Services Facebook Page"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    title="Visit Hunt Pools & Services Facebook Page"
                    aria-label="Visit Hunt Pools & Services Facebook Page"
                  />
                  <SROnly>Hunt Pools & Services Facebook Page</SROnly>
                </a>
              )}
            </SocialContainer>
          </HeaderGrid>
        </StyledWrapper>
      </StyledHeader>
      <Navbar />
    </HeaderContainer>
  )
}

export default Header
